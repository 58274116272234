<template>
  <div class="table_left">
    <div class="title">行政区域</div>

    <el-checkbox v-if="IsTitleShow" v-model="IsLevel" @change="changeIsLevel">只看本级</el-checkbox>

    <div class="content">
      <el-tree
        ref="tree"
        :data="treeData"
        node-key="id"
        highlight-current
        :default-expanded-keys="dck"
        :props="defaultProps"
        @node-click="handleNodeClick"
        :accordion="true"
      ></el-tree>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HcYmhfTemplateTree',
  props: {
    treeData: {
      type: Array,
      default: []
    },
    dck: {
      type: Array,
      default: []
    },
    IsTitleShow: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      OrgId: null,
      downloadObj: {},
      defaultProps: {
        children: 'childRegions',
        label: 'name'
      },
      IsLevel: false,
      treeData1: []
    }
  },
  watch: {
    dck(newValue, oldValue) {
      if (newValue) {
        try {
          this.$nextTick(() => {
            const [id] = newValue || []
            const data = this.$refs.tree.data
            const unexpandArr = data.filter(item => item.id !== id)
            unexpandArr.forEach(item => {
              this.$refs.tree.store.nodesMap[item.id].expanded = false
            })
            this.$refs.tree.setCurrentKey(id)
          })
        } catch (error) {}
      }
    }
  },

  mounted() {},

  methods: {
    handleNodeClick(data) {
      this.OrgId = data.id
      this.downloadObj = data
      this.$emit('refreshNode', this.downloadObj, this.IsLevel)
    },
    changeIsLevel(data) {
      this.$emit('refreshNode', this.downloadObj, data)
    },
    resetLevel() {
      this.IsLevel = false
    }
  }
}
</script>

<style lang="scss" scoped>
.table_left {
  width: 96%;
  border: 1px solid #eeeeee;
  & > .title {
    width: 100%;
    background: #f9fafe;
    padding: 10px 8px;
    font-family: PingFang SC;
    font-size: 18px;
    font-weight: bold;
    line-height: 18px;
    letter-spacing: 0em;
    color: #212a33;
  }
}

::v-deep .el-checkbox {
  width: 96%;
  padding: 8px;
  background: #f1f7fa;
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep .el-checkbox__input {
  margin-top: 3px;
}

::v-deep .el-tree-node__content:hover,
.el-upload-list__item:hover {
  background-color: #ecf5ff;
}

::v-deep .el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
  background-color: #ecf5ff;
  // border: 1px solid #ecf5ff;
  color: #409eff;
}
</style>
