import { render, staticRenderFns } from "./policyDocument.vue?vue&type=template&id=1b1c6a6a&scoped=true"
import script from "./policyDocument.vue?vue&type=script&lang=js"
export * from "./policyDocument.vue?vue&type=script&lang=js"
import style0 from "./policyDocument.vue?vue&type=style&index=0&id=1b1c6a6a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b1c6a6a",
  null
  
)

export default component.exports