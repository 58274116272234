import {
    axios
} from '@/utils/request'

//监评资金列表 (政策文件)
export function _BudgetExpenditurePolicyPaperPage(params) {
    return axios({
        url: '/BudgetExpenditure/PolicyPaperPage',
        method: 'get',
        params,
    })
}

// //监评资金新增 (政策文件)
export function _BudgetExpenditurePolicyPaperAdd(params) {
    return axios({
        url: '/BudgetExpenditure/PolicyPaperAdd',
        method: 'post',
        data: params,
    })
}


// 监评资金详情 (政策文件)
export function _BudgetExpenditurePolicyPaperInfo(params) {
    return axios({
        url: '/BudgetExpenditure/PolicyPaperInfo',
        method: 'get',
        params,
    })
}


// 监评资金编辑 (政策文件)
export function _BudgetExpenditurePolicyPaperEdit(params) {
    return axios({
        url: '/BudgetExpenditure/PolicyPaperEdit',
        method: 'post',
        data: params,
    })
}


// 监评资金删除 (政策文件)
export function _BudgetExpenditurePolicyPaperSelect(params) {
    return axios({
        url: '/BudgetExpenditure/PolicyPaperSelect',
        method: 'get',
        params,
    })
}

// 监评资金列表,下拉框 (政策文件)
export function _BudgetExpenditurePolicyPaperDelete(params) {
    return axios({
        url: '/BudgetExpenditure/PolicyPaperDelete',
        method: 'post',
        data: params,
    })
}


// 列表 (预算分解和支付)
export function _BudgetExpenditurePage(params) {
    return axios({
        url: '/BudgetExpenditure/Page',
        method: 'get',
        params,
    })
}


// 新增 (预算分解和支付)
export function _BudgetExpenditureAdd(params) {
    return axios({
        url: '/BudgetExpenditure/Add',
        method: 'post',
        data: params,
    })
}

// 详情 (预算分解和支付)
export function _BudgetExpenditureInfo(params) {
    return axios({
        url: '/BudgetExpenditure/Info',
        method: 'get',
        params,
    })
}

// 编辑 (预算分解和支付)
export function _BudgetExpenditureEdit(params) {
    return axios({
        url: '/BudgetExpenditure/Edit',
        method: 'post',
        data: params,
    })
}


// 删除 (预算分解和支付)
export function _BudgetExpenditureDelete(params) {
    return axios({
        url: '/BudgetExpenditure/Delete',
        method: 'post',
        data: params,
    })
}

// 导入
export function _BudgetExpenditureImport(params) {
    return axios({
        url: '/BudgetExpenditure/Import',
        method: 'get',
        params,
    })
}

// 导入
export function _BudgetExpenditureExport(params) {
    return axios({
        url: '/BudgetExpenditure/Export',
        method: 'get',
        params,
        responseType: 'blob',
    })
}









