<template>
  <div>
    <div class="table_box" v-if="componentName1 == 'defaultPage'">
      <div class="box1">
        <templateTree ref="templateTree" :treeData="treeData" :dck="dck" @refreshNode="refreshNode" />
      </div>

      <div class="box2">
        <div class="table_right">
          <!-- 搜索筛选 -->
          <el-form
            :inline="true"
            :model="searchObj"
            :rules="rules"
            ref="form"
            style="align-items: center;margin-bottom: 4px;"
            class="user-search flex-between"
            @submit.native.prevent
          >
            <div>
              <!-- <el-form-item label="预算年度" prop="year">
        <budgetYear ref="budgetYear" @refreshYear="refreshYear" />
        </el-form-item>

        <el-form-item label="选择文件" prop="year">
        <budgetYear ref="budgetYear" @refreshYear="refreshYear" />
        </el-form-item>

        <el-form-item>
          <el-button size="mini" plain icon="el-icon-search" @click="search"></el-button>
        </el-form-item>

        <el-form-item>
          <el-button size="mini" plain icon="el-icon-refresh-right" @click="reset"></el-button>
        </el-form-item> -->
              <i class="el-icon-arrow-left goback" @click="handleBack">返回</i>
            </div>

            <div>
              <el-form-item>
                <el-button size="mini" type="primary" icon="el-icon-plus" @click="handleAdd('add')">新建</el-button>
              </el-form-item>
            </div>
          </el-form>

          <div style="position: relative;">
            <el-table
              v-loading="loading"
              ref="table"
              :data="listData"
              border
              style="width: 100%;position: absolute;"
              height="740px"
              :header-cell-style="{ backgroundColor: '#F5F7FA' }"
            >
              <el-table-column fixed type="index" label="序号" width="60" align="center">
                <template slot-scope="scope">
                  <span>{{ (pageparm.currentPage - 1) * pageparm.pageSize + scope.$index + 1 }}</span>
                </template>
              </el-table-column>

              <el-table-column fixed prop="county" align="center" width="80" label="行政区域"></el-table-column>

              <el-table-column align="center" width="170" prop="documentNumber" label="文号"> </el-table-column>
              <el-table-column align="center" prop="documentName" label="文件名称"> </el-table-column>
              <el-table-column align="center" width="130" prop="issuedDate" label="下达时间">
                <template slot-scope="scope">
                  <div>
                    {{ scope.row.issuedDate ? timeFormate(scope.row.issuedDate, 'YYYY-MM-DD') : '' }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" width="130" prop="totalAmount" label="总金额（万元）">
                <template slot-scope="scope">
                  {{ formatToTenThousandWithCustomCommas(scope.row.totalAmount) }}
                </template>
              </el-table-column>

              <el-table-column fixed="right" align="center" width="150" prop="name" label="操作">
                <template slot-scope="scope">
                  <div class="flex" style="justify-content: center;">
                    <el-link type="primary" @click="handleView('view', scope.row)">查看</el-link>
                    &nbsp; &nbsp;
                    <el-link type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-link>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
        </div>
      </div>
    </div>

    <handlepolicy
      v-if="componentName1 == 'handlepolicy'"
      ref="handlepolicy"
      @refreshTable="refreshTable"
      @handleBack1="handleBack1"
    >
    </handlepolicy>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import templateTree from '@/components/templateView/templateTree.vue'
import handlepolicy from '@/views/postImmigrationService/monitorAndTheUse/useOfFunds/components/handlepolicy.vue'
import {
  _BudgetExpenditurePolicyPaperPage,
  _BudgetExpenditurePolicyPaperDelete
} from '@/api/modular/postImmigrationService/monitorAndTheUse/useOfFunds.js'
import { _getLevelRegion } from '@/api/keepHome'

export default {
  name: 'HcYmhfPolicyDocument',

  data() {
    return {
      componentName1: 'defaultPage',
      // 分页参数
      pageparm: {
        currentPage: 1,
        pageSize: 10,
        total: null
      },
      listData: [],
      searchObj: {},
      loading: false,
      rules: {},
      treeData: [],
      dck: [],
      OrgId: null,
      downloadObj: {},
      IsLevel: null
    }
  },
  components: {
    Pagination,
    templateTree,
    handlepolicy
  },

  mounted() {
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    add() {
      this.getLevelRegion()
      this.getPage()
    },
    handleResize() {
      this.$nextTick(() => {
        this.$refs.table.doLayout()
      })
    },

    getLevelRegion(val) {
      let params = {
        startLevel: 2,
        endLevel: 3,
        inclusionLevel: true
      }
      _getLevelRegion(params).then(res => {
        if (res.code == 200) {
          this.treeData = res.data
          let provincialCenterId = this.treeData[0].id //默认展开第一个节点
          this.dck = [provincialCenterId]
          this.downloadObj = this.treeData[0]
        }
      })
    },
    handleAdd(type) {
      this.componentName1 = 'handlepolicy'
      this.$emit('changBrumb', type)
      this.$nextTick(() => {
        console.log(this.$refs.handlepolicy)
        this.$refs.handlepolicy.add(type)
      })
    },
    handleBack1() {
      this.componentName1 = 'defaultPage'
    },
    handleView(type, row) {
      this.componentName1 = 'handlepolicy'
      this.$emit('changBrumb', type)
      this.$nextTick(() => {
        this.$refs.handlepolicy.view(type, row)
      })
    },
    handleDelete(index, row) {
      this.$confirm('确定要删除吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const params = [{ id: row.id }]
          _BudgetExpenditurePolicyPaperDelete(params).then(res => {
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '操作成功!'
              })
            this.getPage()
            } else {
              this.$message({
                type: 'error',
                message: res.message
              })
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    refreshTable() {
      this.getPage()
    },
    getPage() {
      // _BudgetExpenditurePolicyPaperPage
      const { currentPage, pageSize, total } = this.pageparm
      this.loading = true
      const params = {
        pageNo: currentPage,
        pageSize: pageSize,
        ...this.searchObj,
        OrgId: this.OrgId,
        IsLevel: this.IsLevel
      }

      // return
      _BudgetExpenditurePolicyPaperPage(params)
        .then(res => {
          if (res.code == 200) {
            this.listData = res.data.rows

            console.log(res, '======获取page页数据=======')

            this.listData = res.data.rows
            this.pageparm.total = res.data.totalRows

           

            this.loading = false
          } else {
            this.loading = false
            this.$message.error('获取失败：' + res.message)
          }
        })
        .finally(res => {
          this.loading = false
        })
    },
    // 分页插件事件
    callFather(parm) {
      this.pageparm.currentPage = parm.currentPage
      this.pageparm.pageSize = parm.pageSize
      this.getPage()
    },
       refreshNode(obj, IsLevel) {
      this.OrgId = obj.id
      this.downloadObj = obj
      this.IsLevel = IsLevel
      this.OrgPids = obj.pids
      this.pageparm = {
        currentPage: 1,
        pageSize: 10,
        total: null
      }
      this.getPage()
    },
    handleBack() {
      this.$emit('handleBack')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/common/scss/postImmigrationService.scss';
@import '@/common/scss/elementui.scss';
::v-deep .el-form-item {
  margin-bottom: 0px;
}

::v-deep tbody tr.el-table__row:nth-child(1) {
  height: 49px;
  border: 0 !important;
  background-color: #fff !important;
  position: relative !important;
  top: 0;
  left: 0;
  z-index: 1;
  td {
    border-top: 0 !important;
    border-bottom: 1px solid #f5f7fa !important;
  }
  td:first-child {
    border-left: 0 !important;
  }
  td:last-child {
    border-right: 0 !important;
  }
}

.goback {
  cursor: pointer;
  font-size: 0.2rem;
}
</style>
